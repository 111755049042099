import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import AuthLayout from "./layouts/Auth";
import ErrorLayout from "./layouts/Error";
import DashboardLayout from "./layouts/Dashboard";
import OverviewView from "./views/Overview";

import HOF from "./components/HOF/HOF";

export default [
  {
    path: "/",
    exact: true,
    component: HOF(() => <Redirect to="/auth/login" />)
  },
  {
    path: "/auth",
    component: AuthLayout,
    routes: [
      {
        path: "/auth/login",
        exact: true,
        component: HOF(lazy(() => import("src/views/Login")))
      },
      {
        path: "/auth/register",
        exact: true,
        component: HOF(lazy(() => import("src/views/Register")))
      },
      {
        path: "/auth/register/generate-password",
        exact: true,
        component: HOF(lazy(() => import("src/views/GeneratePassword")))
      },
      {
        path: "/auth/reset-password",
        exact: true,
        component: HOF(lazy(() => import("src/views/ResetPassword")))
      },
      {
        path: "/auth/new-password",
        exact: true,
        component: HOF(lazy(() => import("src/views/NewPassword")))
      },
      {
        component: HOF(() => <Redirect to="/errors/error-404" />)
      }
    ]
  },
  {
    path: "/errors",
    component: ErrorLayout,
    routes: [
      {
        path: "/errors/error-401",
        exact: true,
        component: HOF(lazy(() => import("src/views/Error401")))
      },
      {
        path: "/errors/error-404",
        exact: true,
        component: HOF(lazy(() => import("src/views/Error404")))
      },
      {
        path: "/errors/error-500",
        exact: true,
        component: HOF(lazy(() => import("src/views/Error500")))
      },
      {
        component: HOF(() => <Redirect to="/errors/error-404" />)
      }
    ]
  },
  {
    route: "*",
    component: DashboardLayout,
    routes: [
      {
        path: "/overview",
        exact: true,
        component: HOF(OverviewView)
      },

      {
        path: "/profile/:id",
        exact: true,
        component: HOF(lazy(() => import("src/views/Profile")))
      },
      {
        path: "/profile/:id/:tab",
        exact: true,
        component: HOF(lazy(() => import("src/views/Profile")))
      },
      {
        path: "/college-profile/:tab",
        exact: true,
        component: HOF(lazy(() => import("src/views/CollegeProfile")))
      },

      {
        path: "/main-page/:tab",
        exact: true,
        component: HOF(lazy(() => import("src/views/MainPage")))
      },
      {
        component: HOF(() => <Redirect to="/errors/error-404" />)
      }
    ]
  }
];
