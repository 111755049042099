import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Card, Typography, Avatar } from "@material-ui/core";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import gradients from "src/utils/gradients";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  details: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap"
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  avatar: {
    backgroundImage: gradients.green,
    height: 48,
    width: 48,
    marginLeft: "1rem"
  }
}));

function OverviewTags({ title, data, className, ...rest }) {
  const classes = useStyles();
  const getIcon = item => {
    switch (item) {
      case "NEW APPLICATION":
        return <OfflineBoltIcon />;
      case "ONGOING APPLICATION":
        return <TrendingUpIcon />;
      case "DUPLICATE APPLICATION":
        return <PriorityHighIcon />;
      case "ACCEPTED APPLICATION":
        return <VerifiedUserIcon />;
      default:
        break;
    }
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <div>
        <Typography component="h3" gutterBottom variant="overline">
          {title}
        </Typography>
        <div className={classes.details}>
          <Typography variant="h3">{data}</Typography>
        </div>
      </div>
      <Avatar className={classes.avatar}>{getIcon(title)}</Avatar>
    </Card>
  );
}

OverviewTags.propTypes = {
  className: PropTypes.string
};

export default OverviewTags;
