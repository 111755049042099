import React from "react";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";

import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  Modal,
  Card,
  CardHeader,
  CardActions,
  Divider,
  Button,
  Grid,
  Typography,
  Checkbox,
  FormHelperText
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  modalLoader: {
    position: "absolute",
    top: "50%",
    left: "47%",
    outline: "none",

    width: 700,
    maxHeight: "100%",
    overflowY: "auto",
    maxWidth: "100%"
  },
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: "100%",
    overflowY: "auto",
    maxWidth: "100%"
  },
  container: {
    marginTop: theme.spacing(3),
    height: 200
  },
  actions: {
    justifyContent: "space-between",
    padding: "6px 6px"
  },
  button: {
    marginRight: theme.spacing(1),
    color: "primary"
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  table: {
    minWidth: 650
  },
  policy: {
    display: "flex",
    alignItems: "center"
  }
}));

function TermsAndConditionsModal({
  showActionButton,
  changeCheckBoxStatus,
  isChecked,
  submitForm,
  open,
  isLoading,
  closeModal,
  className,
  ...rest
}) {
  const classes = useStyles();

  const handleClick = async () => {
    if (isChecked) {
      submitForm();
    }
  };

  return (
    <>
      <Modal
        disableBackdropClick={true}
        onClose={() => {
          closeModal();
        }}
        open={open}
      >
        <Card {...rest} className={clsx(classes.root, className)}>
          <Grid container justify="space-between" direction="row">
            <CardHeader title="Terms and Conditions" />
            <CloseOutlinedIcon
              style={{
                marginTop: "15px",
                marginRight: "15px",
                cursor: "pointer"
              }}
              onClick={() => {
                closeModal();
                changeCheckBoxStatus(false);
              }}
            />
          </Grid>
          <Divider />
          <div
            style={{
              height: "70vh",
              overflowY: "scroll",
              padding: " 1rem 3.5rem"
            }}
          >
            <Typography
              variant="h5"
              style={{ fontSize: "1.25rem", marginBottom: "1.5rem" }}
            >
              College Dashboard Terms & Conditions for Common Application Form
            </Typography>
            <Typography variant="h6">
              This License Agreement For The Common Application form
              ("Agreement") is between you (both the individual accessing or
              using any or all of our Site related services and any single legal
              entity (Institutions) on behalf of which such individual is
              acting) ("You" or "Your") and Scuben Ecommerce Private Limited
              (Common Application Form by EntranceZone)
              <br />
              "Common Application Form Works" or "Works" means Our
              college/university first year admission application, transfer
              applications, teacher and/or counselor evaluations and
              recommendations, school and mid-year reports, instruction
              information and other related works of Common Application in
              whatever media, hard copy, electronic, CD-ROM or otherwise,
              including any updates or supplements provided thereto by Us for
              which You are granted a license pursuant to this Agreement.
              <br />
              “Admissions Data” means any information about potential applicants
              gathered by the Common Application and made available to or
              obtained by a member institution either through direct emails or
              through access to the Common Application site-related services.
              <br />
              “Communication” includes any communication from or signed by a
              member institution official to any prospective student or parent,
              or any school or school counselor.
              <br />
            </Typography>
            <ol style={{ padding: "1rem 2.5rem" }}>
              <li>
                <Typography variant="h6">Copyright license:</Typography>
                <Typography variant="body2">
                  {" "}
                  If You are a Institutions or university that is also a member
                  of the Common Application form, EntranceZone hereby grants you
                  a limited, non-exclusive, non-transferable license (without
                  the right to sublicense) to access CAF Institutions dashboard
                  solely for your own internal educational admissions
                  operations. Under no circumstances you are granted permission
                  to sublicense the application data for any purpose to any
                  third party, affiliate or vendor unless specifically
                  authorized in writing by EntranceZone.
                </Typography>
              </li>
              <br />
              <li>
                <Typography variant="h6">Ownership:</Typography>{" "}
                <Typography variant="body2">
                  EntranceZone will retain all right, title and interest in and
                  to the copyright and any other intellectual property rights in
                  the Common Application Form, subject only to the limited
                  licenses set forth in this Agreement. You do not acquire any
                  other rights, express or implied, in the Works other than
                  those rights expressly granted under this Agreement. The
                  institution will not be able to see email id and phone number
                  of students until students accept the institution provisional
                  admission/scholarship offer.
                </Typography>
              </li>
              <br />
              <li>
                <Typography variant="h6">
                  Transmission of Admissions Data:{" "}
                </Typography>
                <Typography variant="body2">
                  You may not transmit or provide Admissions Data to any
                  non-member institution, third-party vendor, person, or
                  organization.
                </Typography>
              </li>
              <br />
              <li>
                <Typography variant="h6">
                  Use of Admissions Data for Promotional Purposes:
                </Typography>
                <Typography variant="body2">
                  EntranceZone prohibits use of Admissions Data to promote,
                  market, support, or solicit use of any application data. We
                  never share the data to any third party.
                </Typography>
              </li>

              <br />
              <li>
                <Typography variant="h6">
                  Disclaimer of Application Data:
                </Typography>
                <Typography variant="body2">
                  The students fill the CAF fields at their own and to be best
                  to their knowledge. We ask students for Self-Attestation of
                  document/certificate copies to be uploaded on system.
                  University/Institutes partner need to verify the eligibility
                  and authentication of each student data and documents. If
                  there’s any discrepancy/doubt in student data, Institution
                  must ask for clarification through CAF and may provide
                  conditional admission.
                </Typography>
              </li>
              <br />
              <li>
                <Typography variant="h6">
                  Provisional Admission through CAF:
                </Typography>
                <Typography variant="body2">
                  All the admission granted through EntranceZone Common
                  Application Form is consider as provisional admission. The
                  final admission confirmation should be given after original
                  document verification of students by Institution authority.
                  EntranceZone does not guarantee any admission to any students.
                  It’s a medium/platform that connects students and institutions
                  through education portal. Any seat/branch dispute among or
                  between student and Institutions is sole discretion of student
                  and intuitions only. The final admission documents containing
                  stream, course and branch must be checked by student and
                  institutions at their own.
                </Typography>
              </li>
              <br />
              <li>
                <Typography variant="h6">Login Credentials:</Typography>
                <Typography variant="body2">
                  Institution id will be created at CAF college dashboard with
                  official email id or email id provided by institute authorized
                  persons in contact. The pre-format emails are automated on our
                  system and will be sent to students on behalf of institutions.
                  The formats of emails (Acknowledgement of Application, Process
                  Time, Acceptance, Conditional Admission, Rejection, and
                  Duplicate Application) are attached here.
                </Typography>
              </li>
              <br />
              <li>
                <Typography variant="h6"> Time Line:</Typography>
                <Typography variant="body2">
                  Institutions should take action and view applications on daily
                  basis. Preferably Institution should take action and students
                  should be informed about acknowledgement of application within
                  24 hour of application submission or as soon as possible.
                  Whereas, Institutions must verify the data and take action on
                  application within 48 hour of receiving the application. In
                  case of any discrepancy institutions can take help of
                  EntranceZone counsellors.
                </Typography>
              </li>
              <br />
              <li>
                <Typography variant="h6"> Role of EntranceZone:</Typography>
                <Typography variant="body2">
                  EntranceZone will be contacting to students (Via various
                  mediums) on behalf of Institutions. As partner institutions
                  gives this right while signing the CPA agreement. EntranceZone
                  counsellors will make sure that student update the documents
                  and walk-in to campus before admission deadlines.
                </Typography>
              </li>
              <br />
              <li>
                <Typography variant="h6"> Status of Admission:</Typography>
                <Typography variant="body2">
                  It’s a responsibility of all stock holders that they must
                  update the final status of student’s admission. So that
                  counsellor can update on system and students follow up mails
                  can be paused and checked. Whereas, Status will also help both
                  parties in verification of per students admission
                  incentive/commission as per agreement signed.
                </Typography>
              </li>
              <br />
              <li>
                <Typography variant="h6"> Termination:</Typography>
                <Typography variant="body2">
                  This Agreement is effective until terminated. We may terminate
                  this Agreement at any time upon your breach of any of the
                  provisions hereof. Upon 30 days’ notice, with or without
                  cause, any member school may terminate this Agreement with the
                  EntranceZone, and the EntranceZone may terminate this
                  Agreement with any member school. Upon termination of this
                  Agreement, You will be restricted to use all the application
                  data and our services.
                </Typography>
              </li>
              <br />
              <li>
                <Typography variant="h6"> Entire Agreement:</Typography>
                <Typography variant="body2">
                  This Agreement constitutes the entire agreement between the
                  parties with respect to its subject matter and this Agreement
                  supersedes all prior or contemporaneous agreements,
                  representations, or understandings, written or oral, explicit
                  or implied, concerning the subject matter of this Agreement.
                  This Agreement may not be modified or amended except in a
                  writing signed by a duly authorized representative of each
                  party; no other act, document, usage or custom will be deemed
                  to amend or modify this Agreement. It is expressly agreed that
                  the terms of this Agreement will supersede the terms of any of
                  your purchase orders or other ordering documents.
                </Typography>
              </li>
            </ol>
            <Modal
              disableBackdropClick={true}
              hideBackdrop={true}
              open={isLoading}
            >
              <CircularProgress
                className={clsx(classes.modalLoader, className)}
              />
            </Modal>
          </div>

          <Divider />
          {showActionButton ? (
            <CardActions className={classes.actions}>
              <div>
                <div className={classes.policy}>
                  <Checkbox
                    checked={isChecked}
                    className={classes.policyCheckbox}
                    color="primary"
                    name="policy"
                    onChange={() => changeCheckBoxStatus(!isChecked)}
                  />
                  <Typography color="textSecondary" variant="body1">
                    I have read the Terms and Conditions
                  </Typography>
                </div>
                {!isChecked && (
                  <FormHelperText
                    error
                    style={{ marginTop: "0px !important", marginLeft: "11px" }}
                  >
                    Please agree to the Terms and Conditions
                  </FormHelperText>
                )}
              </div>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={handleClick}
              >
                Submit
              </Button>
            </CardActions>
          ) : null}
          <Divider />
        </Card>
      </Modal>
    </>
  );
}

TermsAndConditionsModal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

export default TermsAndConditionsModal;
