import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Typography, Grid, Button } from "@material-ui/core";
import BarChartIcon from "@material-ui/icons/BarChart";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: 250
  },
  summaryButton: {
    backgroundColor: theme.palette.common.white
  },
  barChartIcon: {
    marginRight: theme.spacing(1)
  },
  image: {
    width: "100%",
    maxHeight: 250
  }
}));

function Header({ collegeName, className, ...rest }) {
  const classes = useStyles();
  let history = useHistory();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid
        alignItems="flex-start"
        container
        justify="space-around"
        spacing={1}
      >
        <Grid item md={6} xs={12}>
          <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography component="h1" gutterBottom variant="h3">
                Admission Portal,
              </Typography>

              <Typography gutterBottom variant="subtitle1">
                You may use this portal to review and manage all the
                Applications.
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                edge="start"
                variant="contained"
                onClick={() => history.push("/main-page/pending")}
                style={{ marginTop: "2rem" }}
              >
                <BarChartIcon className={classes.barChartIcon} />
                View Applications
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={6}>
          <img
            alt="Cover"
            className={classes.image}
            src={require("../../images/undraw_growth_analytics_8btt.svg")}
          />
        </Grid>
      </Grid>
    </div>
  );
}

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
