import api from "./api";

const handleCommonFunction = async ({ method, endpoint, data }) => {
  let res;
  switch (method) {
    case "post":
      res = await api.post(endpoint, data);
      break;
    case "get":
      res = await api.get(endpoint, data);
      break;
    case "patch":
      // res = api.post(endpoint, data);
      break;
    case "put":
      // res = api.post(endpoint, data);
      break;
    case "delete":
      //res = api.post(endpoint, data);
      break;

    default:
      break;
  }

  return res;
};
export { handleCommonFunction };
