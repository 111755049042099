export default {
  auth: {
    login: "/api/v1/auth/login",
    signup: "/api/v1/auth/signup",
    forgot_password: "/api/v1/auth/forgot-password",
    new_password: "/api/v1/auth/signup/password-create",
    forget_password_reset: "/api/v1/auth/forgot-password/reset"
  },
  user: {
    auth: "/api/v1/user",
    logout: "/api/v1/user/logout"
  },
  overview: "api/v1/overview",
  // newsletter_subscribers: "/api/v1/newsletter-subscribers",
  application_form_default: "/api/v1/application-form",
  application_form_new: "/api/v1/application-form?type=pending",
  application_form_accepted: "/api/v1/application-form?type=accepted",
  application_form_duplicate: "/api/v1/application-form?type=duplicate",
  application_form_ongoing:
    "/api/v1/application-form?type=conditionally_accepted"
};
