import React, { createContext, useState, useEffect } from "react";
import apiEndpoint from "./utils/apiConstants";
import { useMutation } from "react-query";
import { handleCommonFunction } from "./utils/authenticationAPI";
import _get from "lodash/get";
export const UserContext = createContext();

export const UserProvider = props => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [college_name_nav, setCollege_name_nav] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorState, setErrorState] = useState(null);
  const [userName, setUserName] = useState("");
  const [collegeLogo, setCollegeLogo] = useState("");
  const [pendingCount, setPendingCount] = useState(null);
  const [conditionalCount, setConditionalCount] = useState(null);
  const [mutate, { error, data }] = useMutation(handleCommonFunction);
  useEffect(() => {
    if (data) {
      setIsAuthenticated(true);

      setUserName(_get(data, "data.college_details.full_name", undefined));
      setCollegeLogo(
        _get(data, "data.college_details.logo.display_url", undefined)
      );
      setPendingCount(
        _get(data, "data.college_details.application_count", undefined) &&
          _get(data, "data.college_details.application_count", undefined).find(
            item => item.status === "pending"
          ) &&
          _get(data, "data.college_details.application_count", undefined).find(
            item => item.status === "pending"
          ).count
      );
      setConditionalCount(
        _get(data, "data.college_details.application_count", undefined) &&
          _get(data, "data.college_details.application_count", undefined).find(
            item => item.status === "conditionally_accepted"
          ) &&
          _get(data, "data.college_details.application_count", undefined).find(
            item => item.status === "conditionally_accepted"
          ).count
      );
      setLoading(false);
    } else if (error) {
      setIsAuthenticated(false);
      setLoading(false);
    }
  }, [data, error]);

  const getUserAuth = async () => {
    try {
      await mutate({
        method: "get",
        endpoint: apiEndpoint.user.auth,
        data: {}
      });
    } catch (e) {
      //error handling
      setIsAuthenticated(false);
    }
  };
  return (
    <UserContext.Provider
      value={{
        isAuthenticated,
        loading,
        setLoading,
        errorState,
        setIsAuthenticated,
        setErrorState,
        data,
        getUserAuth,
        college_name_nav,
        setCollege_name_nav,
        userName,
        setUserName,
        collegeLogo,
        setCollegeLogo,
        pendingCount,
        conditionalCount
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
