/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useContext } from "react";
import { ToastProvider, useToasts } from "react-toast-notifications";
import CustomProgress from "../CustomProgress";
import { UserContext } from "../../context";
import { Route, Redirect } from "react-router-dom";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
const App = props => {
  const { isAuthenticated, loading, data, getUserAuth } = useContext(
    UserContext
  );

  const { history, Component, ...rest } = props;

  const { addToast } = useToasts();
  const [isOnline, setIsOnline] = useState(
    window ? window.navigator.onLine : false
  );

  window.addEventListener("online", () => {
    setIsOnline(true);
  });
  window.addEventListener("offline", () => {
    setIsOnline(false);
  });

  document.addEventListener("copy", e => {
    const pagelink = `\n\nRead more at: ${document.location.href}`;
    e.clipboardData.setData("text", document.getSelection() + pagelink);
    e.preventDefault();
  });

  const prevIsOnline = usePrevious(isOnline);
  useEffect(() => {
    if (!isOnline) {
      addToast(`You are not connected to any network ! `, {
        appearance: "info",
        autoDismiss: true
      });
    } else if (prevIsOnline === false && isOnline) {
      addToast(`You are online now ! `, {
        appearance: "info",
        autoDismiss: true
      });
    }
  }, [isOnline, addToast]);

  useEffect(() => {
    //make api only if the authenticated value is null. Else come to the conclusion.
    if (isAuthenticated === null) {
      getUserAuth();
    }
  }, []);
  if (loading) {
    return <CustomProgress />;
  } else {
    //
    return (
      <Route
        {...rest}
        render={props => {
          if (
            props.location.pathname === "/auth/login" ||
            props.location.pathname === "/auth/register/generate-password" ||
            props.location.pathname === "/auth/register" ||
            props.location.pathname === "/auth/reset-password"
          ) {
            return isAuthenticated ? (
              <Redirect
                to={{
                  pathname: "/overview",
                  state: { from: props.location }
                }}
              />
            ) : (
              <Component {...props} />
            );
          } else if (props.location.pathname === "/auth/new-password") {
            return <Component {...props} />;
          } else {
            return isAuthenticated ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: "/auth/login",
                  state: { from: props.location }
                }}
              />
            );
          }
        }}
      />
    );
  }

  //return (isAuthenticated?<Component {...props} />:);
};

const HOF = Component =>
  function ReturnHOF(props) {
    return (
      <div>
        <ToastProvider placement={"top-right"} autoDismissTimeout={6000}>
          <App Component={Component} {...props}></App>
        </ToastProvider>
      </div>
    );
  };
export default HOF;
