import React, { useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/styles";
import {
  Container,
  Grid,
  Typography,
  Paper,
  Breadcrumbs
} from "@material-ui/core";
import Page from "src/components/Page";
import Header from "./Header";
import OverviewTags from "./OverviewTags";
import { handleCommonFunction } from "../../utils/authenticationAPI";
import apiEndpoint from "../../utils/apiConstants";
import { useMutation } from "react-query";
import { UserContext } from "../../context";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  statistics: {
    marginTop: theme.spacing(3)
  },
  notifications: {
    marginTop: theme.spacing(6)
  },
  projects: {
    marginTop: theme.spacing(6)
  },
  todos: {
    marginTop: theme.spacing(6)
  }
}));

function Overview() {
  const classes = useStyles();
  const { setUserName } = useContext(UserContext);
  const history = useHistory();
  const [mutate, { data }] = useMutation(handleCommonFunction);

  const { data: college_data } = data || {};

  const { college_name: collegeName, data: lead_data } = college_data || {};

  const tab = [
    { title: "NEW APPLICATION", data: "pending" },
    { title: "ONGOING APPLICATION", data: "conditionally_accepted" },
    { title: "DUPLICATE APPLICATION", data: "duplicate" },
    { title: "ACCEPTED APPLICATION", data: "accepted" }
  ];
  useEffect(() => {
    if (data) {
      setUserName(data.data && data.data.college_name);
    }
  }, [data, setUserName]);

  const getOverviewData = async () => {
    try {
      await mutate({
        method: "get",
        endpoint: apiEndpoint.overview,
        data: {}
      });
    } catch (error) {
      //error handling
    }
  };
  useEffect(() => {
    getOverviewData();
  }, []);
  return (
    <Page className={classes.root} title="Overview">
      <Container maxWidth="lg">
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" to="/overview">
            Home
          </Link>
        </Breadcrumbs>
        <div style={{ margin: "1rem 0rem" }}>
          <Typography component="h1" gutterBottom variant="h3">
            Hello,
          </Typography>
          <Typography gutterBottom variant="subtitle1">
            {collegeName}
          </Typography>
        </div>
        <Grid container justify="space-between">
          {lead_data &&
            lead_data.map((item, index) => {
              const tabToLink = tab.find(data => data.title === item.title)
                .data;

              return (
                <OverviewTags
                  title={item.title}
                  key={item.title}
                  data={item.data}
                  onClick={() => history.push(`main-page/${tabToLink}`)}
                  style={{ cursor: "pointer" }}
                />
              );
            })}
        </Grid>
        <Paper style={{ marginTop: "1rem", padding: "1rem" }}>
          <Header collegeName={collegeName} />
        </Paper>
        {/*<Statistics data={lead_data} className={classes.statistics} />*/}
      </Container>
    </Page>
  );
}

export default Overview;
